import './App.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
import Footer from './components/Footer/Footer';

import Contact from './pages/Contact/Contact';
import NoPage from './pages/NoPage/NoPage'
import Home from './pages/Home/Home'
import Projects from './pages/Projects/Projects';

const appName = "{ Void IT }"

function App() {
  return (
    <div className='font-primary'>
      <BrowserRouter>
        <Navbar name={ appName } />
          <div className='min-h-screen bg-gray-900'>
            <Routes>
                <Route index element={<Home />} />
                <Route path="contact" element={<Contact />} />
                <Route path="projects" element={<Projects />} />
                <Route path="*" element={<NoPage />} />
            </Routes>
          </div>
        <Footer name={ appName }/>
      </BrowserRouter>
    </div>
  );
}

export default App;
