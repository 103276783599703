import React from 'react'

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

const date = () => new Date().getFullYear();

function Footer(props) {

  const { t } = useTranslation();

  return (
    <footer className="footer items-center p-4 bg-neutral text-neutral-content">
  <div className="items-center grid-flow-col">
  <a href="/#" className="btn btn-ghost normal-case text-xl font-primary">{props.name}</a>
    <p>Copyright © { date() } - {t('rights')}</p>
  </div> 
  <div className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
    <a href="https://www.linkedin.com/in/adil-el-bouazzati/" target="_blank" rel="noreferrer"> 
      <FontAwesomeIcon icon={faLinkedinIn} className="w-6 h-6" />
    </a> 
    <a href="https://instagram.com/voidit.nl" target="_blank" rel="noreferrer"> 
      <FontAwesomeIcon icon={faInstagram} className="w-6 h-6" />
    </a> 
    <a href="mailto:elbouazzati.a@outlook.com"> 
      <FontAwesomeIcon icon={faEnvelope} className="w-6 h-6" />
    </a> 
  </div>
</footer>
  )
}

export default Footer
