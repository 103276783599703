import React from 'react'
import { Link} from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import background from '../../images/background-404.jpg'

function NoPage() {

  const { t } = useTranslation();

  return (
    <div
        className="min-h-screen bg-cover bg-top sm:bg-top"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="mx-auto max-w-7xl px-4 py-16 text-center sm:px-6 sm:py-24 lg:px-8 lg:py-48">
          <p className="text-base font-semibold text-white">404</p>
          <h1 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">{t('404 heading')}</h1>
          <p className="mt-2 text-lg font-medium text-white">
            {t('404 subheading')}
          </p>
          <div className="mt-6">
            <Link
              to="/"
              className="inline-flex items-center rounded-md border bg-white px-4 py-2 text-sm font-medium text-black text-opacity-75 transform transition duration-200 hover:scale-110"
            >
              {t('go back')}
            </Link>
          </div>
        </div>
      </div>
  )
}

export default NoPage