import React, { useState, useEffect, useCallback } from 'react'

import { Link} from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'


function Navbar(props) {

    const { t, i18n } = useTranslation();

    const changeLanguage = useCallback((language) => {
      i18n.changeLanguage(language);
    }, [i18n])

    const [primary, setPrimary] = useState("🇳🇱");
    const [secondary, setSecondary] = useState("🇬🇧");

    const handleClick = (e) => {
      e.preventDefault();
      setPrimary(secondary)
      setSecondary(primary)
    }

    useEffect(() => {
      const language = primary === '🇳🇱' ? "nl" : "en";
      changeLanguage(language);
    }, [primary, changeLanguage]); 

  return (
    <div className="navbar bg-primary text-white sticky top-0 z-50">
    <div className="navbar-start">
      <div className="dropdown">
        <label tabIndex="0" className="btn btn-ghost lg:hidden">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" /></svg>
        </label>
        <ul tabIndex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-primary rounded-box w-52">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/projects">{t('projects')}</Link></li>
          <li><Link to="/about">{t('about')}</Link></li>
        </ul>
      </div>
      <Link to="/" className="btn btn-ghost normal-case text-xl font-primary">{props.name}</Link>
    </div>
    <div className="navbar-center hidden lg:flex">
      <ul className="menu menu-horizontal p-0">
          <li><Link to="/">Home</Link></li>
          <li><Link to="/projects">{t('projects')}</Link></li>
          <li><Link to="/about">{t('about')}</Link></li>
      </ul>
    </div>
    <div className="navbar-end">
      <div className="dropdown dropdown-end">
        <label tabIndex="0" className="btn bg-primary border-none m-1 text-2xl text-white">
        <span className='mr-2'>{primary}</span>
        <FontAwesomeIcon icon={faCaretDown} />
        </label>
        <ul tabIndex="0" className="dropdown-content menu text-2xl shadow bg-primary rounded-box w-20">
          <li><span onClick={handleClick}>{secondary}</span></li>
        </ul>
       </div>
       <Link to="/contact" className="btn text-white">Contact</Link>
    </div>
  </div>
  )
}

Navbar.propTypes = {}

export default Navbar
