import React from 'react'
import { Link} from 'react-router-dom';

import { useTranslation } from 'react-i18next';

function Home() {
  
  const { t } = useTranslation();

  return (
    <div>
    <section className="text-white">
  <div className="px-4 py-32 mx-auto max-w-screen-xl h-screen items-center flex">
    <div className="max-w-3xl mx-auto text-center">
      <h1 className="text-3xl font-extrabold text-transparent sm:text-5xl bg-clip-text bg-gradient-to-r from-green-300 via-blue-500 to-purple-600">
      {t('heading')}
      </h1>

      <p className="max-w-xl mx-auto mt-4 sm:leading-relaxed sm:text-xl">
      {t('subheading')}
      </p>

      <div className="flex flex-wrap justify-center mt-8 gap-4">
        <Link to="/projects" className="block w-full px-12 py-3 text-sm font-medium text-white bg-primary border border-primary rounded sm:w-auto active:text-opacity-75 hover:bg-transparent hover:text-white focus:outline-none focus:ring" href="/get-started">
        {t('projects')}
        </Link>

        <Link to="/" className="block w-full px-12 py-3 text-sm font-medium text-white border border-primary rounded sm:w-auto hover:bg-primary active:bg-primary focus:outline-none focus:ring" href="/about">
         {t('Get in touch')}
        </Link>
      </div>
    </div>
  </div>
</section>
</div>
  )
}

export default Home