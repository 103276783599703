import React, { useState, useEffect } from 'react'

import axios from 'axios';

import { useTranslation } from 'react-i18next';

// clients logo's
import devUpLogo from '../../images/devUP.png'
import eetcaferiadLogo from '../../images/eetcaferiad.png'
import perla4uLogo from '../../images/perla4u.png'
import obaLogo from '../../images/oba.png'

// background images
import heroBg from '../../images/bg-void.jpeg'

const PROJECT_DATA = 'data/project_data.json';


function Projects() {

    const { t } = useTranslation();

    const [projects, setProjects] = useState([])
    const [loading, setLoading] = useState(true);

    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(3);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

    const currentRecords = projects.slice(indexOfFirstRecord, 
        indexOfLastRecord);
    const nPages = Math.ceil(projects.length / recordsPerPage)

    useEffect(() => {
        axios.get(PROJECT_DATA)
              .then(res => {
                  setProjects(res.data);
                  setLoading(false);
              })
              .catch(() => {
                  alert('There was an error while retrieving the data')
              })
    }, [])


  return (
    <div className='md:pt-10'>
  <main>
    <div>
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2"></div>
        <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="relative shadow-xl sm:overflow-hidden sm:rounded-2xl">
            <div className="absolute inset-0">
              <img className="h-full w-full object-cover" src={heroBg} alt="void"/>
              <div className="absolute inset-0 bg-primary mix-blend-multiply"></div>
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1 className="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl">
                <span className="block text-white">{t('projects')}</span>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="">
        <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:px-8">
          <p className="text-center text-base font-semibold text-white">{ "{{ "} {t('partner line')} {" }}" }</p>
          <div className="mt-6 grid grid-cols-2 gap-8 md:grid-cols-6 lg:grid-cols-5">
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 bg-white rounded-md py-4">
              <img className="h-16" src={devUpLogo} alt="dev-UP Logo"/>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 bg-white rounded-md py-4">
              <img className="h-16" src={eetcaferiadLogo} alt="Eetcade Riad Logo"/>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 bg-white rounded-md py-4">
              <img className="h-16" src={perla4uLogo} alt="Perla4u Logo"/>
            </div>
            <div className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 bg-white rounded-md py-4">
              <img className="h-16" src={obaLogo} alt="Perla4u Logo"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='mx-auto max-w-7xl sm:px-6 lg:px-8'>
    <h1 className='text-white text-3xl mb-6'>{"{ "} {t('projects')} {" }"}</h1>
    {loading &&
            [...Array(3)].map((el, index) => (
                <div key={index} className="border border-[#20293A] bg-[#20293A] shadow rounded-md p-4 w-full h-44 mx-auto mb-10">
                    <div className="animate-pulse flex space-x-4">
                    <div className="rounded-full bg-slate-700 h-10 w-10"></div>
                    <div className="flex-1 space-y-6 py-1">
                        <div className="h-2 bg-slate-700 rounded"></div>
                        <div className="space-y-3">
                        <div className="grid grid-cols-3 gap-4">
                            <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                            <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                            <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-700 rounded col-span-2"></div>
                            <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                            <div className="h-2 bg-slate-700 rounded col-span-1"></div>
                        </div>
                        <div className="h-2 bg-slate-700 rounded"></div>
                        </div>
                    </div>
                    </div>
                </div>
            ))
    }
             {!loading &&
                currentRecords.map((project) => (
                    <div key={project.id}>
                        <div className="card lg:card-side bg-base-100 shadow-xl">
                            <img className='hidden lg:block w-64' src={project.banner} alt="banner image"/>
                                <div className="card-body">
                                    <h2 className="card-title">{project.name}</h2>
                                    <p>{project.desciption}</p>
                                    <div className="card-actions justify-end">
                                    <button className="btn btn-primary text-white">{t('view project')}</button>
                                    </div>
                                </div>
                        </div>
                        <div className='divider divide-white mt-10'></div>
                    </div> 
                ))
            }
    <div className="btn-group w-full flex justify-center pb-10">
        {[...Array(nPages)].map((el, number) => (
            <button key={number + 1} onClick={() => setCurrentPage(number + 1)} 
            className={`btn btn-lg ${currentPage === number + 1 ? 'btn-active' : ''}`}>
                {number + 1}</button>
        ))
        }
    </div>
        </div>
  </main>
    </div>
  )
}

export default Projects